@import url('https://fonts.googleapis.com/css2?family=Viga&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;400;500&family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height:100%;
  overflow: hidden;
}

html {
  height:100%;
  font-size: 14px;
}

html * {
  scrollbar-width: thin;
  scrollbar-color: #666 transparent;
}
html *::-webkit-scrollbar {
  width: 5px;
}
html *::-webkit-scrollbar-track {
  background-color: rgb(235, 235, 235);
  border-radius: 3px;
}
html *::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: #006db3; 
}
html *::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 109, 179, 20%); 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height:100%;
}

H1.header {
  font-size: 185%;
  margin: 0 0 15px 0;
  font-weight: 400;
  color: #444;
  text-transform: none;
}

H2.subHeader {
  font-size: 130%;
  margin: 0 0 15px 0;
  font-weight: 400;
  color: #444;
  text-transform: none;
}

.flexLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
