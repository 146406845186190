FORM.TuiForm {

}

FORM.TuiForm > H1 {
    font-size: 150%;
    font-weight: 400;
    margin: 15px 10px;
    text-transform: uppercase;
    color: #444;

}

FORM.TuiForm > .TuiFormGroup {
    border-radius: 10px;
    /*box-shadow: 0 0 10px -1px rgba(176,176,176,1);*/
    border: 1px solid #ccc;
    background-color: #fff;
    /*margin: 5px 5px 10px 5px;*/
    margin-bottom: 10px;
    display: grid;
    grid-template-rows: auto 1fr;
}

FORM.TuiForm > .TuiFormGroup > .TuiFormGroupHeader {
    padding: 15px 25px 0 25px;
    border-bottom: #ccc 1px solid;
    background-color: whitesmoke;
    border-radius: 10px 10px 0 0;
}

FORM.TuiForm > .TuiFormGroup > .TuiFormGroupHeader > H2 {
    font-size: 185%;
    margin: 0 0 15px 0;
    font-weight: 400;
    color: #444;
    text-transform: none;
}

FORM.TuiForm > .TuiFormGroup > SECTION.TuiFormGroupContent {
    padding: 25px;
}

FORM.TuiForm > .TuiFormGroup > SECTION.TuiFormGroupContent P {
    color: #555;
}

FORM.TuiForm > .TuiFormGroup > SECTION.TuiFormGroupContent > H3 {
    font-size: 1.3em;
    margin: 15px 0 10px 0;
    font-weight: 400;
}

FORM.TuiForm > .TuiFormGroup > SECTION.TuiFormGroupContent > H3:first-child {
    margin-top: 0;
}

FORM.TuiForm DIV.TuiFormError {
    background-color: #d81b60;
    color: white;
    padding: 6px;
    display: flex;
    align-items: center;
}
